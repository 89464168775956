import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  name: string;
  disabled?: boolean;
  onClick: () => void;
};

const RegisterButton: React.FC<Props> = ({
  className,
  name,
  disabled = false,
  onClick: handleClick,
}) => {
  return (
    <button
      className={classNames(
        'rounded text-bold py-2.5 px-5 sm:px-4 sm:py-3.5 sm:text-3xs whitespace-nowrap',
        {
          'bg-green border-green-500 text-white cursor-pointer': !disabled,
          'bg-gray-700 hover:bg-gray-700 text-gray-400 cursor-default':
            disabled,
        },
        className
      )}
      onClick={() => {
        handleClick();
        dataLayer(
          {
            op_event: GTMEventInfoType.CLICK_REGISTER_FILTER,
          },
          GTMEventType.CLICK
        );
      }}
      disabled={disabled}
    >
      <FormattedMessage id={name} />
    </button>
  );
};

export default RegisterButton;
