enum Locale {
  EN = 'en',
  KO = 'ko',
  JA = 'ja',
  PT = 'pt',
  ZH_TW = 'zh-TW',
  TH = 'th',
  VI = 'vi',
}

interface ChampionData {
  key: string;
  name: {
    [key in Locale | string]: string;
  };
  image_url: string;
}

export const championDataList: Record<string, ChampionData> = {
  Annie: {
    name: {
      en: 'Annie',
      ko: '애니',
      ja: 'アニー',
      pt: 'Annie',
      'zh-TW': '安妮',
      th: 'Annie',
      vi: 'Annie',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Annie.png',
    key: 'Annie',
  },
  Olaf: {
    name: {
      en: 'Olaf',
      ko: '올라프',
      ja: 'オラフ',
      pt: 'Olaf',
      'zh-TW': '歐拉夫',
      th: 'Olaf',
      vi: 'Olaf',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Olaf.png',
    key: 'Olaf',
  },
  Galio: {
    name: {
      en: 'Galio',
      ko: '갈리오',
      ja: 'ガリオ',
      pt: 'Galio',
      'zh-TW': '加里歐',
      th: 'Galio',
      vi: 'Galio',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Galio.png',
    key: 'Galio',
  },
  TwistedFate: {
    name: {
      en: 'Twisted Fate',
      ko: '트위스티드 페이트',
      ja: 'ツイステッド・フェイト',
      pt: 'Twisted Fate',
      'zh-TW': '逆命',
      th: 'Twisted Fate',
      vi: 'Twisted Fate',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/TwistedFate.png',
    key: 'TwistedFate',
  },
  XinZhao: {
    name: {
      en: 'Xin Zhao',
      ko: '신 짜오',
      ja: 'シン・ジャオ',
      pt: 'Xin Zhao',
      'zh-TW': '趙信',
      th: 'Xin Zhao',
      vi: 'Xin Zhao',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/XinZhao.png',
    key: 'XinZhao',
  },
  Urgot: {
    name: {
      en: 'Urgot',
      ko: '우르곳',
      ja: 'アーゴット',
      pt: 'Urgot',
      'zh-TW': '烏爾加特',
      th: 'Urgot',
      vi: 'Urgot',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Urgot.png',
    key: 'Urgot',
  },
  Leblanc: {
    name: {
      en: 'LeBlanc',
      ko: '르블랑',
      ja: 'ルブラン',
      pt: 'LeBlanc',
      'zh-TW': '勒布朗',
      th: 'LeBlanc',
      vi: 'LeBlanc',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Leblanc.png',
    key: 'Leblanc',
  },
  Vladimir: {
    name: {
      en: 'Vladimir',
      ko: '블라디미르',
      ja: 'ブラッドミア',
      pt: 'Vladimir',
      'zh-TW': '弗拉迪米爾',
      th: 'Vladimir',
      vi: 'Vladimir',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Vladimir.png',
    key: 'Vladimir',
  },
  Fiddlesticks: {
    name: {
      en: 'Fiddlesticks',
      ko: '피들스틱',
      ja: 'フィドルスティックス',
      pt: 'Fiddlesticks',
      'zh-TW': '費德提克',
      th: 'Fiddlesticks',
      vi: 'Fiddlesticks',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Fiddlesticks.png',
    key: 'Fiddlesticks',
  },
  Kayle: {
    name: {
      en: 'Kayle',
      ko: '케일',
      ja: 'ケイル',
      pt: 'Kayle',
      'zh-TW': '凱爾',
      th: 'Kayle',
      vi: 'Kayle',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Kayle.png',
    key: 'Kayle',
  },
  MasterYi: {
    name: {
      en: 'Master Yi',
      ko: '마스터 이',
      ja: 'マスター・イー',
      pt: 'Master Yi',
      'zh-TW': '易大師',
      th: 'Master Yi',
      vi: 'Master Yi',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/MasterYi.png',
    key: 'MasterYi',
  },
  Alistar: {
    name: {
      en: 'Alistar',
      ko: '알리스타',
      ja: 'アリスター',
      pt: 'Alistar',
      'zh-TW': '亞歷斯塔',
      th: 'Alistar',
      vi: 'Alistar',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Alistar.png',
    key: 'Alistar',
  },
  Ryze: {
    name: {
      en: 'Ryze',
      ko: '라이즈',
      ja: 'ライズ',
      pt: 'Ryze',
      'zh-TW': '雷茲',
      th: 'Ryze',
      vi: 'Ryze',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Ryze.png',
    key: 'Ryze',
  },
  Sion: {
    name: {
      en: 'Sion',
      ko: '사이온',
      ja: 'サイオン',
      pt: 'Sion',
      'zh-TW': '賽恩',
      th: 'Sion',
      vi: 'Sion',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Sion.png',
    key: 'Sion',
  },
  Sivir: {
    name: {
      en: 'Sivir',
      ko: '시비르',
      ja: 'シヴィア',
      pt: 'Sivir',
      'zh-TW': '希維爾',
      th: 'Sivir',
      vi: 'Sivir',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Sivir.png',
    key: 'Sivir',
  },
  Soraka: {
    name: {
      en: 'Soraka',
      ko: '소라카',
      ja: 'ソラカ',
      pt: 'Soraka',
      'zh-TW': '索拉卡',
      th: 'Soraka',
      vi: 'Soraka',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Soraka.png',
    key: 'Soraka',
  },
  Teemo: {
    name: {
      en: 'Teemo',
      ko: '티모',
      ja: 'ティーモ',
      pt: 'Teemo',
      'zh-TW': '提摩',
      th: 'Teemo',
      vi: 'Teemo',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Teemo.png',
    key: 'Teemo',
  },
  Tristana: {
    name: {
      en: 'Tristana',
      ko: '트리스타나',
      ja: 'トリスターナ',
      pt: 'Tristana',
      'zh-TW': '崔絲塔娜',
      th: 'Tristana',
      vi: 'Tristana',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Tristana.png',
    key: 'Tristana',
  },
  Warwick: {
    name: {
      en: 'Warwick',
      ko: '워윅',
      ja: 'ワーウィック',
      pt: 'Warwick',
      'zh-TW': '沃維克',
      th: 'Warwick',
      vi: 'Warwick',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Warwick.png',
    key: 'Warwick',
  },
  Nunu: {
    name: {
      en: 'Nunu & Willump',
      ko: '누누와 윌럼프',
      ja: 'ヌヌ＆ウィルンプ',
      pt: 'Nunu e Willump',
      'zh-TW': '努努和威朗普',
      th: 'Nunu & Willump',
      vi: 'Nunu & Willump',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Nunu.png',
    key: 'Nunu',
  },
  MissFortune: {
    name: {
      en: 'Miss Fortune',
      ko: '미스 포츈',
      ja: 'ミス・フォーチュン',
      pt: 'Miss Fortune',
      'zh-TW': '好運姐',
      th: 'Miss Fortune',
      vi: 'Miss Fortune',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/MissFortune.png',
    key: 'MissFortune',
  },
  Ashe: {
    name: {
      en: 'Ashe',
      ko: '애쉬',
      ja: 'アッシュ',
      pt: 'Ashe',
      'zh-TW': '艾希',
      th: 'Ashe',
      vi: 'Ashe',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Ashe.png',
    key: 'Ashe',
  },
  Tryndamere: {
    name: {
      en: 'Tryndamere',
      ko: '트린다미어',
      ja: 'トリンダメア',
      pt: 'Tryndamere',
      'zh-TW': '泰達米爾',
      th: 'Tryndamere',
      vi: 'Tryndamere',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Tryndamere.png',
    key: 'Tryndamere',
  },
  Jax: {
    name: {
      en: 'Jax',
      ko: '잭스',
      ja: 'ジャックス',
      pt: 'Jax',
      'zh-TW': '賈克斯',
      th: 'Jax',
      vi: 'Jax',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Jax.png',
    key: 'Jax',
  },
  Morgana: {
    name: {
      en: 'Morgana',
      ko: '모르가나',
      ja: 'モルガナ',
      pt: 'Morgana',
      'zh-TW': '魔甘娜',
      th: 'Morgana',
      vi: 'Morgana',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Morgana.png',
    key: 'Morgana',
  },
  Zilean: {
    name: {
      en: 'Zilean',
      ko: '질리언',
      ja: 'ジリアン',
      pt: 'Zilean',
      'zh-TW': '極靈',
      th: 'Zilean',
      vi: 'Zilean',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Zilean.png',
    key: 'Zilean',
  },
  Singed: {
    name: {
      en: 'Singed',
      ko: '신지드',
      ja: 'シンジド',
      pt: 'Singed',
      'zh-TW': '辛吉德',
      th: 'Singed',
      vi: 'Singed',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Singed.png',
    key: 'Singed',
  },
  Evelynn: {
    name: {
      en: 'Evelynn',
      ko: '이블린',
      ja: 'イブリン',
      pt: 'Evelynn',
      'zh-TW': '伊芙琳',
      th: 'Evelynn',
      vi: 'Evelynn',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Evelynn.png',
    key: 'Evelynn',
  },
  Twitch: {
    name: {
      en: 'Twitch',
      ko: '트위치',
      ja: 'トゥイッチ',
      pt: 'Twitch',
      'zh-TW': '圖奇',
      th: 'Twitch',
      vi: 'Twitch',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Twitch.png',
    key: 'Twitch',
  },
  Karthus: {
    name: {
      en: 'Karthus',
      ko: '카서스',
      ja: 'カーサス',
      pt: 'Karthus',
      'zh-TW': '卡爾瑟斯',
      th: 'Karthus',
      vi: 'Karthus',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Karthus.png',
    key: 'Karthus',
  },
  Chogath: {
    name: {
      en: "Cho'Gath",
      ko: '초가스',
      ja: 'チョ＝ガス',
      pt: "Cho'Gath",
      'zh-TW': '科加斯',
      th: "Cho'Gath",
      vi: "Cho'Gath",
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Chogath.png',
    key: 'Chogath',
  },
  Amumu: {
    name: {
      en: 'Amumu',
      ko: '아무무',
      ja: 'アムム',
      pt: 'Amumu',
      'zh-TW': '阿姆姆',
      th: 'Amumu',
      vi: 'Amumu',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Amumu.png',
    key: 'Amumu',
  },
  Rammus: {
    name: {
      en: 'Rammus',
      ko: '람머스',
      ja: 'ラムス',
      pt: 'Rammus',
      'zh-TW': '拉姆斯',
      th: 'Rammus',
      vi: 'Rammus',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Rammus.png',
    key: 'Rammus',
  },
  Anivia: {
    name: {
      en: 'Anivia',
      ko: '애니비아',
      ja: 'アニビア',
      pt: 'Anivia',
      'zh-TW': '艾妮維亞',
      th: 'Anivia',
      vi: 'Anivia',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Anivia.png',
    key: 'Anivia',
  },
  Shaco: {
    name: {
      en: 'Shaco',
      ko: '샤코',
      ja: 'シャコ',
      pt: 'Shaco',
      'zh-TW': '薩科',
      th: 'Shaco',
      vi: 'Shaco',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Shaco.png',
    key: 'Shaco',
  },
  DrMundo: {
    name: {
      en: 'Dr. Mundo',
      ko: '문도 박사',
      ja: 'ドクター・ムンド',
      pt: 'Dr. Mundo',
      'zh-TW': '蒙多醫生',
      th: 'Dr. Mundo',
      vi: 'Dr. Mundo',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/DrMundo.png',
    key: 'DrMundo',
  },
  Sona: {
    name: {
      en: 'Sona',
      ko: '소나',
      ja: 'ソナ',
      pt: 'Sona',
      'zh-TW': '索娜',
      th: 'Sona',
      vi: 'Sona',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Sona.png',
    key: 'Sona',
  },
  Kassadin: {
    name: {
      en: 'Kassadin',
      ko: '카사딘',
      ja: 'カサディン',
      pt: 'Kassadin',
      'zh-TW': '卡薩丁',
      th: 'Kassadin',
      vi: 'Kassadin',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Kassadin.png',
    key: 'Kassadin',
  },
  Irelia: {
    name: {
      en: 'Irelia',
      ko: '이렐리아',
      ja: 'イレリア',
      pt: 'Irelia',
      'zh-TW': '伊瑞莉雅',
      th: 'Irelia',
      vi: 'Irelia',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Irelia.png',
    key: 'Irelia',
  },
  Janna: {
    name: {
      en: 'Janna',
      ko: '잔나',
      ja: 'ジャンナ',
      pt: 'Janna',
      'zh-TW': '珍娜',
      th: 'Janna',
      vi: 'Janna',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Janna.png',
    key: 'Janna',
  },
  Gangplank: {
    name: {
      en: 'Gangplank',
      ko: '갱플랭크',
      ja: 'ガングプランク',
      pt: 'Gangplank',
      'zh-TW': '剛普朗克',
      th: 'Gangplank',
      vi: 'Gangplank',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Gangplank.png',
    key: 'Gangplank',
  },
  Corki: {
    name: {
      en: 'Corki',
      ko: '코르키',
      ja: 'コーキ',
      pt: 'Corki',
      'zh-TW': '庫奇',
      th: 'Corki',
      vi: 'Corki',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Corki.png',
    key: 'Corki',
  },
  Karma: {
    name: {
      en: 'Karma',
      ko: '카르마',
      ja: 'カルマ',
      pt: 'Karma',
      'zh-TW': '卡瑪',
      th: 'Karma',
      vi: 'Karma',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Karma.png',
    key: 'Karma',
  },
  Taric: {
    name: {
      en: 'Taric',
      ko: '타릭',
      ja: 'タリック',
      pt: 'Taric',
      'zh-TW': '塔里克',
      th: 'Taric',
      vi: 'Taric',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Taric.png',
    key: 'Taric',
  },
  Veigar: {
    name: {
      en: 'Veigar',
      ko: '베이가',
      ja: 'ベイガー',
      pt: 'Veigar',
      'zh-TW': '維迦',
      th: 'Veigar',
      vi: 'Veigar',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Veigar.png',
    key: 'Veigar',
  },
  Trundle: {
    name: {
      en: 'Trundle',
      ko: '트런들',
      ja: 'トランドル',
      pt: 'Trundle',
      'zh-TW': '特朗德',
      th: 'Trundle',
      vi: 'Trundle',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Trundle.png',
    key: 'Trundle',
  },
  Swain: {
    name: {
      en: 'Swain',
      ko: '스웨인',
      ja: 'スウェイン',
      pt: 'Swain',
      'zh-TW': '斯溫',
      th: 'Swain',
      vi: 'Swain',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Swain.png',
    key: 'Swain',
  },
  Caitlyn: {
    name: {
      en: 'Caitlyn',
      ko: '케이틀린',
      ja: 'ケイトリン',
      pt: 'Caitlyn',
      'zh-TW': '凱特琳',
      th: 'Caitlyn',
      vi: 'Caitlyn',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Caitlyn.png',
    key: 'Caitlyn',
  },
  Blitzcrank: {
    name: {
      en: 'Blitzcrank',
      ko: '블리츠크랭크',
      ja: 'ブリッツクランク',
      pt: 'Blitzcrank',
      'zh-TW': '布里茨',
      th: 'Blitzcrank',
      vi: 'Blitzcrank',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Blitzcrank.png',
    key: 'Blitzcrank',
  },
  Malphite: {
    name: {
      en: 'Malphite',
      ko: '말파이트',
      ja: 'マルファイト',
      pt: 'Malphite',
      'zh-TW': '墨菲特',
      th: 'Malphite',
      vi: 'Malphite',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Malphite.png',
    key: 'Malphite',
  },
  Katarina: {
    name: {
      en: 'Katarina',
      ko: '카타리나',
      ja: 'カタリナ',
      pt: 'Katarina',
      'zh-TW': '卡特蓮娜',
      th: 'Katarina',
      vi: 'Katarina',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Katarina.png',
    key: 'Katarina',
  },
  Nocturne: {
    name: {
      en: 'Nocturne',
      ko: '녹턴',
      ja: 'ノクターン',
      pt: 'Nocturne',
      'zh-TW': '夜曲',
      th: 'Nocturne',
      vi: 'Nocturne',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Nocturne.png',
    key: 'Nocturne',
  },
  Maokai: {
    name: {
      en: 'Maokai',
      ko: '마오카이',
      ja: 'マオカイ',
      pt: 'Maokai',
      'zh-TW': '茂凱',
      th: 'Maokai',
      vi: 'Maokai',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Maokai.png',
    key: 'Maokai',
  },
  Renekton: {
    name: {
      en: 'Renekton',
      ko: '레넥톤',
      ja: 'レネクトン',
      pt: 'Renekton',
      'zh-TW': '雷尼克頓',
      th: 'Renekton',
      vi: 'Renekton',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Renekton.png',
    key: 'Renekton',
  },
  JarvanIV: {
    name: {
      en: 'Jarvan IV',
      ko: '자르반 4세',
      ja: 'ジャーヴァンⅣ',
      pt: 'Jarvan IV',
      'zh-TW': '嘉文四世',
      th: 'Jarvan IV',
      vi: 'Jarvan IV',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/JarvanIV.png',
    key: 'JarvanIV',
  },
  Elise: {
    name: {
      en: 'Elise',
      ko: '엘리스',
      ja: 'エリス',
      pt: 'Elise',
      'zh-TW': '伊莉絲',
      th: 'Elise',
      vi: 'Elise',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Elise.png',
    key: 'Elise',
  },
  Orianna: {
    name: {
      en: 'Orianna',
      ko: '오리아나',
      ja: 'オリアナ',
      pt: 'Orianna',
      'zh-TW': '奧莉安娜',
      th: 'Orianna',
      vi: 'Orianna',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Orianna.png',
    key: 'Orianna',
  },
  MonkeyKing: {
    name: {
      en: 'Wukong',
      ko: '오공',
      ja: 'ウーコン',
      pt: 'Wukong',
      'zh-TW': '悟空',
      th: 'Wukong',
      vi: 'Ngộ Không',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/MonkeyKing.png',
    key: 'MonkeyKing',
  },
  Brand: {
    name: {
      en: 'Brand',
      ko: '브랜드',
      ja: 'ブランド',
      pt: 'Brand',
      'zh-TW': '布蘭德',
      th: 'Brand',
      vi: 'Brand',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Brand.png',
    key: 'Brand',
  },
  LeeSin: {
    name: {
      en: 'Lee Sin',
      ko: '리 신',
      ja: 'リー・シン',
      pt: 'Lee Sin',
      'zh-TW': '李星',
      th: 'Lee Sin',
      vi: 'Lee Sin',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/LeeSin.png',
    key: 'LeeSin',
  },
  Vayne: {
    name: {
      en: 'Vayne',
      ko: '베인',
      ja: 'ヴェイン',
      pt: 'Vayne',
      'zh-TW': '汎',
      th: 'Vayne',
      vi: 'Vayne',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Vayne.png',
    key: 'Vayne',
  },
  Rumble: {
    name: {
      en: 'Rumble',
      ko: '럼블',
      ja: 'ランブル',
      pt: 'Rumble',
      'zh-TW': '藍寶',
      th: 'Rumble',
      vi: 'Rumble',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Rumble.png',
    key: 'Rumble',
  },
  Cassiopeia: {
    name: {
      en: 'Cassiopeia',
      ko: '카시오페아',
      ja: 'カシオペア',
      pt: 'Cassiopeia',
      'zh-TW': '卡莎碧雅',
      th: 'Cassiopeia',
      vi: 'Cassiopeia',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Cassiopeia.png',
    key: 'Cassiopeia',
  },
  Skarner: {
    name: {
      en: 'Skarner',
      ko: '스카너',
      ja: 'スカーナー',
      pt: 'Skarner',
      'zh-TW': '史加納',
      th: 'Skarner',
      vi: 'Skarner',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Skarner.png',
    key: 'Skarner',
  },
  Heimerdinger: {
    name: {
      en: 'Heimerdinger',
      ko: '하이머딩거',
      ja: 'ハイマーディンガー',
      pt: 'Heimerdinger',
      'zh-TW': '漢默丁格',
      th: 'Heimerdinger',
      vi: 'Heimerdinger',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Heimerdinger.png',
    key: 'Heimerdinger',
  },
  Nasus: {
    name: {
      en: 'Nasus',
      ko: '나서스',
      ja: 'ナサス',
      pt: 'Nasus',
      'zh-TW': '納瑟斯',
      th: 'Nasus',
      vi: 'Nasus',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Nasus.png',
    key: 'Nasus',
  },
  Nidalee: {
    name: {
      en: 'Nidalee',
      ko: '니달리',
      ja: 'ニダリー',
      pt: 'Nidalee',
      'zh-TW': '奈德麗',
      th: 'Nidalee',
      vi: 'Nidalee',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Nidalee.png',
    key: 'Nidalee',
  },
  Udyr: {
    name: {
      en: 'Udyr',
      ko: '우디르',
      ja: 'ウディア',
      pt: 'Udyr',
      'zh-TW': '烏迪爾',
      th: 'Udyr',
      vi: 'Udyr',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Udyr.png',
    key: 'Udyr',
  },
  Poppy: {
    name: {
      en: 'Poppy',
      ko: '뽀삐',
      ja: 'ポッピー',
      pt: 'Poppy',
      'zh-TW': '波比',
      th: 'Poppy',
      vi: 'Poppy',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Poppy.png',
    key: 'Poppy',
  },
  Gragas: {
    name: {
      en: 'Gragas',
      ko: '그라가스',
      ja: 'グラガス',
      pt: 'Gragas',
      'zh-TW': '古拉格斯',
      th: 'Gragas',
      vi: 'Gragas',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Gragas.png',
    key: 'Gragas',
  },
  Pantheon: {
    name: {
      en: 'Pantheon',
      ko: '판테온',
      ja: 'パンテオン',
      pt: 'Pantheon',
      'zh-TW': '潘森',
      th: 'Pantheon',
      vi: 'Pantheon',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Pantheon.png',
    key: 'Pantheon',
  },
  Ezreal: {
    name: {
      en: 'Ezreal',
      ko: '이즈리얼',
      ja: 'エズリアル',
      pt: 'Ezreal',
      'zh-TW': '伊澤瑞爾',
      th: 'Ezreal',
      vi: 'Ezreal',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Ezreal.png',
    key: 'Ezreal',
  },
  Mordekaiser: {
    name: {
      en: 'Mordekaiser',
      ko: '모데카이저',
      ja: 'モルデカイザー',
      pt: 'Mordekaiser',
      'zh-TW': '魔鬥凱薩',
      th: 'Mordekaiser',
      vi: 'Mordekaiser',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Mordekaiser.png',
    key: 'Mordekaiser',
  },
  Yorick: {
    name: {
      en: 'Yorick',
      ko: '요릭',
      ja: 'ヨリック',
      pt: 'Yorick',
      'zh-TW': '約瑞科',
      th: 'Yorick',
      vi: 'Yorick',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Yorick.png',
    key: 'Yorick',
  },
  Akali: {
    name: {
      en: 'Akali',
      ko: '아칼리',
      ja: 'アカリ',
      pt: 'Akali',
      'zh-TW': '阿卡莉',
      th: 'Akali',
      vi: 'Akali',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Akali.png',
    key: 'Akali',
  },
  Kennen: {
    name: {
      en: 'Kennen',
      ko: '케넨',
      ja: 'ケネン',
      pt: 'Kennen',
      'zh-TW': '凱能',
      th: 'Kennen',
      vi: 'Kennen',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Kennen.png',
    key: 'Kennen',
  },
  Garen: {
    name: {
      en: 'Garen',
      ko: '가렌',
      ja: 'ガレン',
      pt: 'Garen',
      'zh-TW': '蓋倫',
      th: 'Garen',
      vi: 'Garen',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Garen.png',
    key: 'Garen',
  },
  Leona: {
    name: {
      en: 'Leona',
      ko: '레오나',
      ja: 'レオナ',
      pt: 'Leona',
      'zh-TW': '雷歐娜',
      th: 'Leona',
      vi: 'Leona',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Leona.png',
    key: 'Leona',
  },
  Malzahar: {
    name: {
      en: 'Malzahar',
      ko: '말자하',
      ja: 'マルザハール',
      pt: 'Malzahar',
      'zh-TW': '馬爾札哈',
      th: 'Malzahar',
      vi: 'Malzahar',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Malzahar.png',
    key: 'Malzahar',
  },
  Talon: {
    name: {
      en: 'Talon',
      ko: '탈론',
      ja: 'タロン',
      pt: 'Talon',
      'zh-TW': '塔隆',
      th: 'Talon',
      vi: 'Talon',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Talon.png',
    key: 'Talon',
  },
  Riven: {
    name: {
      en: 'Riven',
      ko: '리븐',
      ja: 'リヴェン',
      pt: 'Riven',
      'zh-TW': '雷玟',
      th: 'Riven',
      vi: 'Riven',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Riven.png',
    key: 'Riven',
  },
  KogMaw: {
    name: {
      en: "Kog'Maw",
      ko: '코그모',
      ja: 'コグ＝マウ',
      pt: "Kog'Maw",
      'zh-TW': '寇格魔',
      th: "Kog'Maw",
      vi: "Kog'Maw",
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/KogMaw.png',
    key: 'KogMaw',
  },
  Shen: {
    name: {
      en: 'Shen',
      ko: '쉔',
      ja: 'シェン',
      pt: 'Shen',
      'zh-TW': '慎',
      th: 'Shen',
      vi: 'Shen',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Shen.png',
    key: 'Shen',
  },
  Lux: {
    name: {
      en: 'Lux',
      ko: '럭스',
      ja: 'ラックス',
      pt: 'Lux',
      'zh-TW': '拉克絲',
      th: 'Lux',
      vi: 'Lux',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Lux.png',
    key: 'Lux',
  },
  Xerath: {
    name: {
      en: 'Xerath',
      ko: '제라스',
      ja: 'ゼラス',
      pt: 'Xerath',
      'zh-TW': '齊勒斯',
      th: 'Xerath',
      vi: 'Xerath',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Xerath.png',
    key: 'Xerath',
  },
  Shyvana: {
    name: {
      en: 'Shyvana',
      ko: '쉬바나',
      ja: 'シヴァーナ',
      pt: 'Shyvana',
      'zh-TW': '希瓦娜',
      th: 'Shyvana',
      vi: 'Shyvana',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Shyvana.png',
    key: 'Shyvana',
  },
  Ahri: {
    name: {
      en: 'Ahri',
      ko: '아리',
      ja: 'アーリ',
      pt: 'Ahri',
      'zh-TW': '阿璃',
      th: 'Ahri',
      vi: 'Ahri',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Ahri.png',
    key: 'Ahri',
  },
  Graves: {
    name: {
      en: 'Graves',
      ko: '그레이브즈',
      ja: 'グレイブス',
      pt: 'Graves',
      'zh-TW': '葛雷夫',
      th: 'Graves',
      vi: 'Graves',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Graves.png',
    key: 'Graves',
  },
  Fizz: {
    name: {
      en: 'Fizz',
      ko: '피즈',
      ja: 'フィズ',
      pt: 'Fizz',
      'zh-TW': '飛斯',
      th: 'Fizz',
      vi: 'Fizz',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Fizz.png',
    key: 'Fizz',
  },
  Volibear: {
    name: {
      en: 'Volibear',
      ko: '볼리베어',
      ja: 'ボリベア',
      pt: 'Volibear',
      'zh-TW': '弗力貝爾',
      th: 'Volibear',
      vi: 'Volibear',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Volibear.png',
    key: 'Volibear',
  },
  Rengar: {
    name: {
      en: 'Rengar',
      ko: '렝가',
      ja: 'レンガー',
      pt: 'Rengar',
      'zh-TW': '雷葛爾',
      th: 'Rengar',
      vi: 'Rengar',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Rengar.png',
    key: 'Rengar',
  },
  Varus: {
    name: {
      en: 'Varus',
      ko: '바루스',
      ja: 'ヴァルス',
      pt: 'Varus',
      'zh-TW': '法洛士',
      th: 'Varus',
      vi: 'Varus',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Varus.png',
    key: 'Varus',
  },
  Nautilus: {
    name: {
      en: 'Nautilus',
      ko: '노틸러스',
      ja: 'ノーチラス',
      pt: 'Nautilus',
      'zh-TW': '納帝魯斯',
      th: 'Nautilus',
      vi: 'Nautilus',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Nautilus.png',
    key: 'Nautilus',
  },
  Viktor: {
    name: {
      en: 'Viktor',
      ko: '빅토르',
      ja: 'ビクター',
      pt: 'Viktor',
      'zh-TW': '維克特',
      th: 'Viktor',
      vi: 'Viktor',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Viktor.png',
    key: 'Viktor',
  },
  Sejuani: {
    name: {
      en: 'Sejuani',
      ko: '세주아니',
      ja: 'セジュアニ',
      pt: 'Sejuani',
      'zh-TW': '史瓦妮',
      th: 'Sejuani',
      vi: 'Sejuani',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Sejuani.png',
    key: 'Sejuani',
  },
  Fiora: {
    name: {
      en: 'Fiora',
      ko: '피오라',
      ja: 'フィオラ',
      pt: 'Fiora',
      'zh-TW': '菲歐拉',
      th: 'Fiora',
      vi: 'Fiora',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Fiora.png',
    key: 'Fiora',
  },
  Ziggs: {
    name: {
      en: 'Ziggs',
      ko: '직스',
      ja: 'ジグス',
      pt: 'Ziggs',
      'zh-TW': '希格斯',
      th: 'Ziggs',
      vi: 'Ziggs',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Ziggs.png',
    key: 'Ziggs',
  },
  Lulu: {
    name: {
      en: 'Lulu',
      ko: '룰루',
      ja: 'ルル',
      pt: 'Lulu',
      'zh-TW': '露璐',
      th: 'Lulu',
      vi: 'Lulu',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Lulu.png',
    key: 'Lulu',
  },
  Draven: {
    name: {
      en: 'Draven',
      ko: '드레이븐',
      ja: 'ドレイヴン',
      pt: 'Draven',
      'zh-TW': '達瑞文',
      th: 'Draven',
      vi: 'Draven',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Draven.png',
    key: 'Draven',
  },
  Hecarim: {
    name: {
      en: 'Hecarim',
      ko: '헤카림',
      ja: 'ヘカリム',
      pt: 'Hecarim',
      'zh-TW': '赫克林',
      th: 'Hecarim',
      vi: 'Hecarim',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Hecarim.png',
    key: 'Hecarim',
  },
  Khazix: {
    name: {
      en: "Kha'Zix",
      ko: '카직스',
      ja: 'カ＝ジックス',
      pt: "Kha'Zix",
      'zh-TW': '卡力斯',
      th: "Kha'Zix",
      vi: "Kha'Zix",
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Khazix.png',
    key: 'Khazix',
  },
  Darius: {
    name: {
      en: 'Darius',
      ko: '다리우스',
      ja: 'ダリウス',
      pt: 'Darius',
      'zh-TW': '達瑞斯',
      th: 'Darius',
      vi: 'Darius',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Darius.png',
    key: 'Darius',
  },
  Jayce: {
    name: {
      en: 'Jayce',
      ko: '제이스',
      ja: 'ジェイス',
      pt: 'Jayce',
      'zh-TW': '杰西',
      th: 'Jayce',
      vi: 'Jayce',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Jayce.png',
    key: 'Jayce',
  },
  Lissandra: {
    name: {
      en: 'Lissandra',
      ko: '리산드라',
      ja: 'リサンドラ',
      pt: 'Lissandra',
      'zh-TW': '麗珊卓',
      th: 'Lissandra',
      vi: 'Lissandra',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Lissandra.png',
    key: 'Lissandra',
  },
  Diana: {
    name: {
      en: 'Diana',
      ko: '다이애나',
      ja: 'ダイアナ',
      pt: 'Diana',
      'zh-TW': '黛安娜',
      th: 'Diana',
      vi: 'Diana',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Diana.png',
    key: 'Diana',
  },
  Quinn: {
    name: {
      en: 'Quinn',
      ko: '퀸',
      ja: 'クイン',
      pt: 'Quinn',
      'zh-TW': '葵恩',
      th: 'Quinn',
      vi: 'Quinn',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Quinn.png',
    key: 'Quinn',
  },
  Syndra: {
    name: {
      en: 'Syndra',
      ko: '신드라',
      ja: 'シンドラ',
      pt: 'Syndra',
      'zh-TW': '星朵拉',
      th: 'Syndra',
      vi: 'Syndra',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Syndra.png',
    key: 'Syndra',
  },
  AurelionSol: {
    name: {
      en: 'Aurelion Sol',
      ko: '아우렐리온 솔',
      ja: 'オレリオン・ソル',
      pt: 'Aurelion Sol',
      'zh-TW': '翱銳龍獸',
      th: 'Aurelion Sol',
      vi: 'Aurelion Sol',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/AurelionSol.png',
    key: 'AurelionSol',
  },
  Kayn: {
    name: {
      en: 'Kayn',
      ko: '케인',
      ja: 'ケイン',
      pt: 'Kayn',
      'zh-TW': '慨影',
      th: 'Kayn',
      vi: 'Kayn',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Kayn.png',
    key: 'Kayn',
  },
  Zoe: {
    name: {
      en: 'Zoe',
      ko: '조이',
      ja: 'ゾーイ',
      pt: 'Zoe',
      'zh-TW': '柔依',
      th: 'Zoe',
      vi: 'Zoe',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Zoe.png',
    key: 'Zoe',
  },
  Zyra: {
    name: {
      en: 'Zyra',
      ko: '자이라',
      ja: 'ザイラ',
      pt: 'Zyra',
      'zh-TW': '枷蘿',
      th: 'Zyra',
      vi: 'Zyra',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Zyra.png',
    key: 'Zyra',
  },
  Kaisa: {
    name: {
      en: "Kai'Sa",
      ko: '카이사',
      ja: 'カイ＝サ',
      pt: "Kai'Sa",
      'zh-TW': '凱莎',
      th: "Kai'Sa",
      vi: "Kai'Sa",
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Kaisa.png',
    key: 'Kaisa',
  },
  Seraphine: {
    name: {
      en: 'Seraphine',
      ko: '세라핀',
      ja: 'セラフィーン',
      pt: 'Seraphine',
      'zh-TW': '瑟菈紛',
      th: 'Seraphine',
      vi: 'Seraphine',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Seraphine.png',
    key: 'Seraphine',
  },
  Gnar: {
    name: {
      en: 'Gnar',
      ko: '나르',
      ja: 'ナー',
      pt: 'Gnar',
      'zh-TW': '吶兒',
      th: 'Gnar',
      vi: 'Gnar',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Gnar.png',
    key: 'Gnar',
  },
  Zac: {
    name: {
      en: 'Zac',
      ko: '자크',
      ja: 'ザック',
      pt: 'Zac',
      'zh-TW': '札克',
      th: 'Zac',
      vi: 'Zac',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Zac.png',
    key: 'Zac',
  },
  Yasuo: {
    name: {
      en: 'Yasuo',
      ko: '야스오',
      ja: 'ヤスオ',
      pt: 'Yasuo',
      'zh-TW': '犽宿',
      th: 'Yasuo',
      vi: 'Yasuo',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Yasuo.png',
    key: 'Yasuo',
  },
  Velkoz: {
    name: {
      en: "Vel'Koz",
      ko: '벨코즈',
      ja: 'ヴェル＝コズ',
      pt: "Vel'Koz",
      'zh-TW': '威寇茲',
      th: "Vel'Koz",
      vi: "Vel'Koz",
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Velkoz.png',
    key: 'Velkoz',
  },
  Taliyah: {
    name: {
      en: 'Taliyah',
      ko: '탈리야',
      ja: 'タリヤ',
      pt: 'Taliyah',
      'zh-TW': '塔莉雅',
      th: 'Taliyah',
      vi: 'Taliyah',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Taliyah.png',
    key: 'Taliyah',
  },
  Camille: {
    name: {
      en: 'Camille',
      ko: '카밀',
      ja: 'カミール',
      pt: 'Camille',
      'zh-TW': '卡蜜兒',
      th: 'Camille',
      vi: 'Camille',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Camille.png',
    key: 'Camille',
  },
  Akshan: {
    name: {
      en: 'Akshan',
      ko: '아크샨',
      ja: 'アクシャン',
      pt: 'Akshan',
      'zh-TW': '埃可尚',
      th: 'Akshan',
      vi: 'Akshan',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Akshan.png',
    key: 'Akshan',
  },
  Belveth: {
    name: {
      en: "Bel'Veth",
      ko: '벨베스',
      ja: 'ベル＝ヴェス',
      pt: "Bel'Veth",
      'zh-TW': '貝爾薇斯',
      th: "Bel'Veth",
      vi: "Bel'Veth",
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Belveth.png',
    key: 'Belveth',
  },
  Braum: {
    name: {
      en: 'Braum',
      ko: '브라움',
      ja: 'ブラウム',
      pt: 'Braum',
      'zh-TW': '布郎姆',
      th: 'Braum',
      vi: 'Braum',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Braum.png',
    key: 'Braum',
  },
  Jhin: {
    name: {
      en: 'Jhin',
      ko: '진',
      ja: 'ジン',
      pt: 'Jhin',
      'zh-TW': '燼',
      th: 'Jhin',
      vi: 'Jhin',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Jhin.png',
    key: 'Jhin',
  },
  Kindred: {
    name: {
      en: 'Kindred',
      ko: '킨드레드',
      ja: 'キンドレッド',
      pt: 'Kindred',
      'zh-TW': '鏡爪',
      th: 'Kindred',
      vi: 'Kindred',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Kindred.png',
    key: 'Kindred',
  },
  Zeri: {
    name: {
      en: 'Zeri',
      ko: '제리',
      ja: 'ゼリ',
      pt: 'Zeri',
      'zh-TW': '婕莉',
      th: 'Zeri',
      vi: 'Zeri',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Zeri.png',
    key: 'Zeri',
  },
  Jinx: {
    name: {
      en: 'Jinx',
      ko: '징크스',
      ja: 'ジンクス',
      pt: 'Jinx',
      'zh-TW': '吉茵珂絲',
      th: 'Jinx',
      vi: 'Jinx',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Jinx.png',
    key: 'Jinx',
  },
  TahmKench: {
    name: {
      en: 'Tahm Kench',
      ko: '탐 켄치',
      ja: 'タム・ケンチ',
      pt: 'Tahm Kench',
      'zh-TW': '貪啃奇',
      th: 'Tahm Kench',
      vi: 'Tahm Kench',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/TahmKench.png',
    key: 'TahmKench',
  },
  Briar: {
    name: {
      en: 'Briar',
      ko: '브라이어',
      ja: 'ブライアー',
      pt: 'Briar',
      'zh-TW': '布蕾爾',
      th: 'Briar',
      vi: 'Briar',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Briar.png',
    key: 'Briar',
  },
  Viego: {
    name: {
      en: 'Viego',
      ko: '비에고',
      ja: 'ヴィエゴ',
      pt: 'Viego',
      'zh-TW': '維爾戈',
      th: 'Viego',
      vi: 'Viego',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Viego.png',
    key: 'Viego',
  },
  Senna: {
    name: {
      en: 'Senna',
      ko: '세나',
      ja: 'セナ',
      pt: 'Senna',
      'zh-TW': '姍娜',
      th: 'Senna',
      vi: 'Senna',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Senna.png',
    key: 'Senna',
  },
  Lucian: {
    name: {
      en: 'Lucian',
      ko: '루시안',
      ja: 'ルシアン',
      pt: 'Lucian',
      'zh-TW': '路西恩',
      th: 'Lucian',
      vi: 'Lucian',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Lucian.png',
    key: 'Lucian',
  },
  Zed: {
    name: {
      en: 'Zed',
      ko: '제드',
      ja: 'ゼド',
      pt: 'Zed',
      'zh-TW': '劫',
      th: 'Zed',
      vi: 'Zed',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Zed.png',
    key: 'Zed',
  },
  Kled: {
    name: {
      en: 'Kled',
      ko: '클레드',
      ja: 'クレッド',
      pt: 'Kled',
      'zh-TW': '克雷德',
      th: 'Kled',
      vi: 'Kled',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Kled.png',
    key: 'Kled',
  },
  Ekko: {
    name: {
      en: 'Ekko',
      ko: '에코',
      ja: 'エコー',
      pt: 'Ekko',
      'zh-TW': '艾克',
      th: 'Ekko',
      vi: 'Ekko',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Ekko.png',
    key: 'Ekko',
  },
  Qiyana: {
    name: {
      en: 'Qiyana',
      ko: '키아나',
      ja: 'キヤナ',
      pt: 'Qiyana',
      'zh-TW': '姬亞娜',
      th: 'Qiyana',
      vi: 'Qiyana',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Qiyana.png',
    key: 'Qiyana',
  },
  Vi: {
    name: {
      en: 'Vi',
      ko: '바이',
      ja: 'ヴァイ',
      pt: 'Vi',
      'zh-TW': '菲艾',
      th: 'Vi',
      vi: 'Vi',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Vi.png',
    key: 'Vi',
  },
  Aatrox: {
    name: {
      en: 'Aatrox',
      ko: '아트록스',
      ja: 'エイトロックス',
      pt: 'Aatrox',
      'zh-TW': '厄薩斯',
      th: 'Aatrox',
      vi: 'Aatrox',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Aatrox.png',
    key: 'Aatrox',
  },
  Nami: {
    name: {
      en: 'Nami',
      ko: '나미',
      ja: 'ナミ',
      pt: 'Nami',
      'zh-TW': '娜米',
      th: 'Nami',
      vi: 'Nami',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Nami.png',
    key: 'Nami',
  },
  Azir: {
    name: {
      en: 'Azir',
      ko: '아지르',
      ja: 'アジール',
      pt: 'Azir',
      'zh-TW': '阿祈爾',
      th: 'Azir',
      vi: 'Azir',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Azir.png',
    key: 'Azir',
  },
  Yuumi: {
    name: {
      en: 'Yuumi',
      ko: '유미',
      ja: 'ユーミ',
      pt: 'Yuumi',
      'zh-TW': '悠咪',
      th: 'Yuumi',
      vi: 'Yuumi',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Yuumi.png',
    key: 'Yuumi',
  },
  Samira: {
    name: {
      en: 'Samira',
      ko: '사미라',
      ja: 'サミーラ',
      pt: 'Samira',
      'zh-TW': '煞蜜拉',
      th: 'Samira',
      vi: 'Samira',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Samira.png',
    key: 'Samira',
  },
  Thresh: {
    name: {
      en: 'Thresh',
      ko: '쓰레쉬',
      ja: 'スレッシュ',
      pt: 'Thresh',
      'zh-TW': '瑟雷西',
      th: 'Thresh',
      vi: 'Thresh',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Thresh.png',
    key: 'Thresh',
  },
  Illaoi: {
    name: {
      en: 'Illaoi',
      ko: '일라오이',
      ja: 'イラオイ',
      pt: 'Illaoi',
      'zh-TW': '伊羅旖',
      th: 'Illaoi',
      vi: 'Illaoi',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Illaoi.png',
    key: 'Illaoi',
  },
  RekSai: {
    name: {
      en: "Rek'Sai",
      ko: '렉사이',
      ja: 'レク＝サイ',
      pt: "Rek'Sai",
      'zh-TW': '雷珂煞',
      th: "Rek'Sai",
      vi: "Rek'Sai",
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/RekSai.png',
    key: 'RekSai',
  },
  Ivern: {
    name: {
      en: 'Ivern',
      ko: '아이번',
      ja: 'アイバーン',
      pt: 'Ivern',
      'zh-TW': '埃爾文',
      th: 'Ivern',
      vi: 'Ivern',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Ivern.png',
    key: 'Ivern',
  },
  Kalista: {
    name: {
      en: 'Kalista',
      ko: '칼리스타',
      ja: 'カリスタ',
      pt: 'Kalista',
      'zh-TW': '克黎思妲',
      th: 'Kalista',
      vi: 'Kalista',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Kalista.png',
    key: 'Kalista',
  },
  Bard: {
    name: {
      en: 'Bard',
      ko: '바드',
      ja: 'バード',
      pt: 'Bardo',
      'zh-TW': '巴德',
      th: 'Bard',
      vi: 'Bard',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Bard.png',
    key: 'Bard',
  },
  Rakan: {
    name: {
      en: 'Rakan',
      ko: '라칸',
      ja: 'ラカン',
      pt: 'Rakan',
      'zh-TW': '銳空',
      th: 'Rakan',
      vi: 'Rakan',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Rakan.png',
    key: 'Rakan',
  },
  Xayah: {
    name: {
      en: 'Xayah',
      ko: '자야',
      ja: 'ザヤ',
      pt: 'Xayah',
      'zh-TW': '剎雅',
      th: 'Xayah',
      vi: 'Xayah',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Xayah.png',
    key: 'Xayah',
  },
  Ornn: {
    name: {
      en: 'Ornn',
      ko: '오른',
      ja: 'オーン',
      pt: 'Ornn',
      'zh-TW': '鄂爾',
      th: 'Ornn',
      vi: 'Ornn',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Ornn.png',
    key: 'Ornn',
  },
  Sylas: {
    name: {
      en: 'Sylas',
      ko: '사일러스',
      ja: 'サイラス',
      pt: 'Sylas',
      'zh-TW': '賽勒斯',
      th: 'Sylas',
      vi: 'Sylas',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Sylas.png',
    key: 'Sylas',
  },
  Neeko: {
    name: {
      en: 'Neeko',
      ko: '니코',
      ja: 'ニーコ',
      pt: 'Neeko',
      'zh-TW': '妮可',
      th: 'Neeko',
      vi: 'Neeko',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Neeko.png',
    key: 'Neeko',
  },
  Aphelios: {
    name: {
      en: 'Aphelios',
      ko: '아펠리오스',
      ja: 'アフェリオス',
      pt: 'Aphelios',
      'zh-TW': '亞菲利歐',
      th: 'Aphelios',
      vi: 'Aphelios',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Aphelios.png',
    key: 'Aphelios',
  },
  Rell: {
    name: {
      en: 'Rell',
      ko: '렐',
      ja: 'レル',
      pt: 'Rell',
      'zh-TW': '銳兒',
      th: 'Rell',
      vi: 'Rell',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Rell.png',
    key: 'Rell',
  },
  Pyke: {
    name: {
      en: 'Pyke',
      ko: '파이크',
      ja: 'パイク',
      pt: 'Pyke',
      'zh-TW': '派克',
      th: 'Pyke',
      vi: 'Pyke',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Pyke.png',
    key: 'Pyke',
  },
  Vex: {
    name: {
      en: 'Vex',
      ko: '벡스',
      ja: 'ヴェックス',
      pt: 'Vex',
      'zh-TW': '薇可絲',
      th: 'Vex',
      vi: 'Vex',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Vex.png',
    key: 'Vex',
  },
  Yone: {
    name: {
      en: 'Yone',
      ko: '요네',
      ja: 'ヨネ',
      pt: 'Yone',
      'zh-TW': '犽凝',
      th: 'Yone',
      vi: 'Yone',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Yone.png',
    key: 'Yone',
  },
  Sett: {
    name: {
      en: 'Sett',
      ko: '세트',
      ja: 'セト',
      pt: 'Sett',
      'zh-TW': '賽特',
      th: 'Sett',
      vi: 'Sett',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Sett.png',
    key: 'Sett',
  },
  Lillia: {
    name: {
      en: 'Lillia',
      ko: '릴리아',
      ja: 'リリア',
      pt: 'Lillia',
      'zh-TW': '莉莉亞',
      th: 'Lillia',
      vi: 'Lillia',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Lillia.png',
    key: 'Lillia',
  },
  Gwen: {
    name: {
      en: 'Gwen',
      ko: '그웬',
      ja: 'グウェン',
      pt: 'Gwen',
      'zh-TW': '關',
      th: 'Gwen',
      vi: 'Gwen',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Gwen.png',
    key: 'Gwen',
  },
  Renata: {
    name: {
      en: 'Renata Glasc',
      ko: '레나타 글라스크',
      ja: 'レナータ・グラスク',
      pt: 'Renata Glasc',
      'zh-TW': '睿娜妲．格萊斯克',
      th: 'Renata Glasc',
      vi: 'Renata Glasc',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Renata.png',
    key: 'Renata',
  },
  Aurora: {
    name: {
      en: 'Aurora',
      ko: '오로라',
      ja: 'オーロラ',
      pt: 'Aurora',
      'zh-TW': '歐羅拉',
      th: 'Aurora',
      vi: 'Aurora',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Aurora.png',
    key: 'Aurora',
  },
  Nilah: {
    name: {
      en: 'Nilah',
      ko: '닐라',
      ja: 'ニーラ',
      pt: 'Nilah',
      'zh-TW': '淣菈',
      th: 'Nilah',
      vi: 'Nilah',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Nilah.png',
    key: 'Nilah',
  },
  KSante: {
    name: {
      en: "K'Sante",
      ko: '크산테',
      ja: 'カ・サンテ',
      pt: "K'Sante",
      'zh-TW': '卡桑帝',
      th: "K'Sante",
      vi: "K'Sante",
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/KSante.png',
    key: 'KSante',
  },
  Smolder: {
    name: {
      en: 'Smolder',
      ko: '스몰더',
      ja: 'スモルダー',
      pt: 'Smolder',
      'zh-TW': '史矛德',
      th: 'Smolder',
      vi: 'Smolder',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Smolder.png',
    key: 'Smolder',
  },
  Milio: {
    name: {
      en: 'Milio',
      ko: '밀리오',
      ja: 'ミリオ',
      pt: 'Milio',
      'zh-TW': '米里歐',
      th: 'Milio',
      vi: 'Milio',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Milio.png',
    key: 'Milio',
  },
  Hwei: {
    name: {
      en: 'Hwei',
      ko: '흐웨이',
      ja: 'フェイ',
      pt: 'Hwei',
      'zh-TW': '赫威',
      th: 'Hwei',
      vi: 'Hwei',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Hwei.png',
    key: 'Hwei',
  },
  Naafiri: {
    name: {
      en: 'Naafiri',
      ko: '나피리',
      ja: 'ナフィーリ',
      pt: 'Naafiri',
      'zh-TW': '娜菲芮',
      th: 'Naafiri',
      vi: 'Naafiri',
    },
    image_url:
      'https://opgg-static.akamaized.net/meta/images/lol/latest/champion/Naafiri.png',
    key: 'Naafiri',
  },
};
